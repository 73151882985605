import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useColorModeValue,
    Text
} from '@chakra-ui/react'

import PrimaryButton from './PrimaryButton'
import TextButton from './TextButton'

import useGlobalAlertDialog from '../../Stores/useGlobalAlertDialog'
import useHandleError from '../../Stores/useHandleError'

const GlobalAlertDialog = () => {
    const alertData = useGlobalAlertDialog((state) => state.alertData)
    const closeGlobalAlertDialog = useGlobalAlertDialog((state) => state.closeGlobalAlertDialog)
    const hideError = useHandleError((state) => state.hideError)

    const bgColor = useColorModeValue('gray.100', 'gray.800')

    return (
        <AlertDialog
            motionPreset={'slideInBottom'}
            onClose={closeGlobalAlertDialog}
            isOpen={alertData.isOpen}
            isCentered
            onCloseComplete={() => {
                hideError()
            }}
        >
            <AlertDialogOverlay />

            <AlertDialogContent bgColor={bgColor}>
                <AlertDialogHeader fontWeight={'bold'}>{alertData?.title}</AlertDialogHeader>
                <AlertDialogBody>
                    <Text fontSize={'lg'}>
                        {alertData?.message}
                    </Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                    {alertData?.secondaryButton?.label &&
                        <TextButton
                            label={alertData.secondaryButton.label}
                            onClick={alertData.secondaryButton.handler}
                        />
                    }

                    {alertData?.primaryButton?.label &&
                        <PrimaryButton
                            label={alertData.primaryButton.label}
                            onClick={alertData.primaryButton.handler}
                        />
                    }


                    {/* <TextButton
                        label={'Close'}
                        onClick={closeGlobalAlertDialog}
                    />
                    {alertData.action.label &&
                        <PrimaryButton
                            label={alertData.action.label}
                            onClick={alertData.action.handler}
                        />
                    } */}
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default GlobalAlertDialog