import { create } from 'zustand'

const useHandleError = create((set) => ({
    error: {
        active: false,
        errorType: null,
        message: null
    },
    displayError: (data) => set((state) => ({
        error: {
            active: true,
            errorType: data?.errorType,
            message: data?.message
        }
    })),
    hideError: () => set((state) => ({
        error: {
            active: false,
            errorType: null,
            message: null
        }
    }))
}))


export default useHandleError